import type { HandleClientError } from "@sveltejs/kit";
import consent from "./utils/consent";
import * as env from "$env/static/public";

let sentryErrorHandler: HandleClientError | undefined;
if ((env as any).PUBLIC_SENTRY_DSN) {
  consent.subscribe(async ({ purposes }) => {
    if (purposes?.productImprovement) {
      const module = await import("./sentryErrorHandler");
      sentryErrorHandler = module.default as HandleClientError;
    }
  });
}
export const handleError = (input) => {
  if (sentryErrorHandler) {
    return sentryErrorHandler(input);
  }
  return undefined;
};
